<template>
  <section>
    <div class="ma-10">
      <div class="d-flex">
        <div class="d-flex align-center">
          <v-icon @click="$router.back()" size="22" class="mr-2"
            >mdi-arrow-left</v-icon
          >
          Tickets
        </div>
        <v-icon size="18" class="mx-2">mdi-slash-forward</v-icon>
        <div class="body-1"># {{ ticket.id }}</div>
      </div>
      <div class="mt-5">
        <div class="col-sm-12 col-lg-4">
          <label class="caption">TICKET STATUS</label>
          <v-select
            :items="$helpers.ticketStat()"
            item-text="text"
            item-value="value"
            v-model="ticket.status"
            v-on:input="status($event)"
          />
        </div>
        <div class="col-sm-12 col-lg-6">
          <label class="caption">NOTE</label>
          <vue-editor
            rows="2"
            v-model="ticket.note"
            v-on:input="addNote($event)"
          />
        </div>
        <div class="col-sm-12 col-lg-8">
          <label class="caption">CONCERN</label>
          <div class="pt-3" v-html="ticket.body"></div>
        </div>
      </div>
    </div>
    <snackbar
      :snack="snack"
      text="Ticket Updated"
      timeout="2000"
      @close="snack = false"
    />
  </section>
</template>

<script>
import { mapActions, mapState } from "vuex";
export default {
  data: () => ({
    snack: false,
    note: "",
  }),
  computed: {
    ...mapState("admin", {
      ticket: (state) => state.tickets,
    }),
  },
  mounted() {
    this.showTicket(this.$route.params.uuid);
  },
  methods: {
    ...mapActions("admin", ["showTicket", "updateTicket", "noteTicket"]),

    status(e) {
      console.log(e);
      this.updateTicket({
        uuid: this.ticket.uuid,
        status: e,
      }).then(() => {
        this.snack = true;
      });
    },

    addNote(e) {
      this.noteTicket({
        uuid: this.$route.params.uuid,
        note: e,
      });
    },
  },
};
</script>